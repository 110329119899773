import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SearchInput from '../SeachInput';

//contexts
import AdminGlobalContext from '../../contexts/AdminGlobalContext/GlobalContext';

//styles
import TableContainer from './styles';

export default function Table({ is_clickable, Thead, Tbody, setQuery, searchInputPlaceholder, forceUpdate }) {

    const { setEditableUser } = useContext(AdminGlobalContext);

    const history = useHistory(); 

    const handle_click = (data) => {
        if(is_clickable !== true) return
        setEditableUser(data)
        history.push('/admin/panel/edit/single');
    }

    return (
        <TableContainer>
            <div className='table'>
                <Thead />
                <Tbody handle_click={handle_click} />
            </div>
        </TableContainer>
    );
}
