import React, { useEffect, useState, useContext, useRef } from "react";
import { Form } from "@unform/web";

//services
import Api from "../../../service/api";

//contexts
import AlertContext from "../../../contexts/Alert/AlertContext";

//components
import Input from "../../../components/Input";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import DeleteForever from "@material-ui/icons/DeleteForever";
import RemoveIcon from "@material-ui/icons/Remove";

//styles
import UserContainer from "./styles";
import Loading from "../../../components/Loading";
import { useHistory } from "react-router-dom";

export default function CreateUser() {
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const [fields, setFields] = useState({
    fields: {
        firstField: '',
        secondField: '',
    }
  })
  const { setAlert } = useContext(AlertContext);

  const formRef = useRef(null);

  const [newQuestions, setNewQuestions] = useState([]);
  useEffect(() => {
      Api.get('/admin/loginFields').then((response) => {
          setFields(response.data.fields)
      })
  }, [])
  const handle_submit = async (payload, { reset }) => {
    setLoading(true);
    payload.active = true;
    await Api.post("/admin/users", payload)
      .then((res) => {
        setAlert({
          type: "ok",
          severity: "success",
          message: "Ação efetuada com sucesso.",
          show: true,
        });
        history.push("/admin/panel/users");
        reset();
      })
      .catch((error) => {
        console.log(" Data :", error.response);
        setAlert({
          type: "error",
          severity: "error",
          message: error?.response?.data?.msg,
          show: true
        });
      });

    setLoading(false);
  };

  const add_input = () => {
    if (newQuestions.length < 2) {
      setNewQuestions([...newQuestions, 1]);
    }
  };

  const minus_input = () => {
    let newQuestionsList = newQuestions.slice();
    newQuestionsList.shift();
    setNewQuestions(newQuestionsList);
  };

  const handle_clear = () => {
    formRef.current.reset();
    setAlert({
      type: "ok",
      severity: "success",
      message: "Campos restaurados com sucesso.",
      show: true,
    });
  };

  const make_submit = () => {
    formRef.current.submitForm();
  };

  return (
    <UserContainer>
      {loading ? <Loading /> : ""}
      <Form onSubmit={handle_submit} ref={formRef}>
        <header>
          <h2>Criação Única</h2>
          <div className="add_holder">
            <label htmlFor="clear_button">Limpar</label>
            <IconButton id="clear_button" size="small" color="inherit" onClick={handle_clear} title="Limpar Campos">
              <DeleteForever fontSize="large" />
            </IconButton>
            <label htmlFor="submit_button">Adicionar</label>
            <IconButton id="submit_button" size="small" color="inherit" onClick={make_submit} title="Criar Usuário">
              <AddCircle fontSize="large" />
            </IconButton>
          </div>
        </header>
        <div className="content_holder">
          <div className="first_section">
            <h3>Informações gerais</h3>
            <label>*Nome:</label>
            <Input name="name" required maxlength={60}/>
            <label>*{fields.firstField}:</label>
            <Input name="id" required maxlength={40}/>
            <label>*{fields.secondField}:</label>
            <Input name="num_doc" required maxlength={30}/>
            <label>Custom ID:</label>
            <Input name="customId" maxlength={60}/>
            <label>Cargo:</label>
            <Input name="title" maxlength={60}/>
            <label>E-mail:</label>
            <Input name="email" maxlength={100}/>
            <label>Telefone: </label>
            <Input name="phoneNumber" maxlength={20}/>
            <label>Localidade/Cidade:</label>
            <Input name="locality" maxlength={60}/>
            <label>Departamento:</label>
            <Input name="department" maxlength={60}/>
            <label>Organização:</label>
            <Input name="organization" maxlength={60}/>
            <label>Divisão:</label>
            <Input name="division" maxlength={60}/>
          </div>
          <div className="second_section">
            <h3>Informações de autenticação</h3>
            <label>
              <p>*Perguntas</p>
              <div>
                <IconButton size="small" color="inherit" onClick={minus_input}>
                  <RemoveIcon />
                </IconButton>
                <IconButton size="small" color="inherit" onClick={add_input}>
                  <AddIcon />
                </IconButton>
              </div>
            </label>
            <Input name="questions.0" required maxlength={50}/>
            <Input name="questions.1" required maxlength={50}/>
            <Input name="questions.2" required maxlength={50}/>
            {newQuestions.map((item, index) => (
              <Input key={`${index}_${item}`} name={`questions.${index + 3}`} maxlength={50}/>
            ))}
            <label>
              <p>*Respostas</p>
              <div>
                <IconButton size="small" color="inherit" onClick={minus_input}>
                  <RemoveIcon />
                </IconButton>
                <IconButton size="small" color="inherit" onClick={add_input}>
                  <AddIcon />
                </IconButton>
              </div>
            </label>
            <Input name="answers.0" required maxlength={50}/>
            <Input name="answers.1" required maxlength={50}/>
            <Input name="answers.2" required maxlength={50}/>
            {newQuestions.map((item, index) => (
              <Input key={`${index}_${item}`} name={`answers.${index + 3}`} maxlength={50}/>
            ))}
          </div>
        </div>
      </Form>
    </UserContainer>
  );
}
