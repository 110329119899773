import React, { useContext, useEffect, useState } from "react";
//resources
import Button from "../../../components/Button";
import AlertContext from "../../../contexts/Alert/AlertContext";
import AuthContext from "../../../contexts/Auth/AuthContext";
//contexts
import TokenContext from "../../../contexts/Token/TokenContext";
import UserContext from "../../../contexts/UserGlobalContext/UserContext";
import Api from "../../../service/api";
//styles
import InfoContainer from "./styles.js";

export default function Information({ location }) {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowedToContinue, setIsAllowedToContinue] = useState(true);

  const { setLoading } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const { token } = useContext(TokenContext);

  const { userInformation } = useContext(AuthContext);
  const { id, numDoc } = userInformation;

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (!!location.state && location.state.method === "forgot") {
        setLoading(true);
        Api.post(
          "user/resetPassword",
          {
            id: userInformation.id,
            numDoc: userInformation.numDoc,
            wpId: user.wpId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
          setIsAllowedToContinue(false);
          setLoading(false);
        });
      } else {
        setIsAllowedToContinue(false);
      }
    }
  }, [user, location]);

  useEffect(() => {
    setLoading(true);
    Api.get("/user/information", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          setAlert({
            type: "ok",
            severity: "success",
            message: "Usuário carregado com sucesso.",
            show: true,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setAlert({
          type: "not ok",
          severity: "error",
          message:
            "Um erro desconhecido ocorreu. Por favor, tente novamente mais tarde.",
          show: true,
        });
        setLoading(false);
      });
  }, [id, numDoc, setAlert, setLoading, token]);

  const define_password = async () => {
    setIsLoading(true);
    const loginUser = await Api.get("/user/login-user-in-workplace", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => res.data);

    if (!loginUser.accessCode) {
      // alert("Sem accessCode");
      window.location.href = loginUser.claimLink;
    } else {
      // alert("Com accessCode");
      window.location.href = `https://work.workplace.com/work/accesscode/?access_code=${loginUser.accessCode}`;
    }
  };

  return (
    <InfoContainer>
      <h2>Informações do usuário</h2>
      <ul>
        <li>
          <strong>Data do cadastro:</strong> {user.registerDate}
        </li>
        <li>
          <strong>Nome completo:</strong> {user.formattedName}
        </li>
        <li>
          <strong>Matrícula:</strong> {user.externalId}
        </li>
        <li>
          <strong>E-mail:</strong>{" "}
          <b>{user.email ? user.email : "Sem email"}</b>
        </li>
        <li>
          <strong>Status:</strong> {user.status ? "Sim" : "Não"}
        </li>
        <li>
          <strong>Conta reivindicada:</strong> {user.claimed ? "Sim" : "Não"}
        </li>
        <li>
          <strong>Conta convidada:</strong> {user.invited ? "Sim" : "Não"}
        </li>
        <li>
          <strong>Data do convite:</strong> {user.inviteDate}
        </li>
      </ul>
      <div className="button_holder">
      <Button onClick={define_password} disabled={isLoading}>
          Definir senha!
        </Button>
      </div>
    </InfoContainer>
  );
}
