import axios from 'axios';
import { appCheck } from './firebase';
import { getToken } from '@firebase/app-check';

const instance = () => {
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_API_URL}/backend`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const instance = axios.create(defaultOptions);

  return instance
}

const Api = instance();
Api.interceptors.request.use(async function (config) {
  const appCheckToken = await getToken(appCheck);

  config.headers['X-Firebase-AppCheck'] = appCheckToken.token;
  return config;
});

export default Api;
