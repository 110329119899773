import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    *, *:before, *:after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body, #root{
        font-family: 'Roboto', Arial;
        font-size: 10px;
        width: 100%;
        height: 100%;
    }

    #root::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: url('https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/ClaimPortalBackgroundImage.png');
        background-size: cover;
        filter: contrast(180%) brightness(35%) saturate(40%);
    }
    
    .MuiLinearProgress-colorPrimary{
        background: ${props => props.theme.loader.secundary} !important;
        background-color: ${props => props.theme.loader.secundary} !important; 
    }

    .MuiLinearProgress-barColorPrimary{
        background: ${props => props.theme.loader.primary} !important;
        background-color: ${props => props.theme.loader.primary} !important;
    }

    //flex

    html, body, #root{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 425px) {
        #root::before {
            background: ${props => props.theme.secundary};
            filter: none;
        }
      }

`;