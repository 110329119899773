import styled from "styled-components";

export const SearchStyles = styled.div`
  .searchInputContainer {
    display: flex;
    flex-direction: row;
    width: 200px;
    margin-right: 25px;
    align-items: center;
    
    .inputSearch {
      height: 35px;
      border-radius: 10px;
      padding-right: 30px;
      padding-left: 10px;
      border-color: #1976d2;
    }

    .searchIcon {
      position: relative;
      right: 45px;
      color: #1976d2;

      :hover{
        color: #0A3966;
      }
    }
  }
`;
