import React, { useContext } from 'react';

//styles
import WelcomeContactContainer from './styles';

import ContactContext from '../../contexts/Contact/ContactContext';

export default function WelcomeContact() {
  const { messageContact, contact } = useContext(ContactContext);
  return (
    <WelcomeContactContainer>
      <div className="contact">
        {messageContact ? (
          <span>{messageContact}</span>
        ) : (
          ((contact.contact1.title && contact.contact1.number) ||
            (contact.contact2.title && contact.contact2.number) ||
            (contact.contact3.title && contact.contact3.number) ||
            (contact.contact4.title && contact.contact4.number)) && (
            <span>Contacte-nos em caso de dúvidas</span>
          )
        )}
        {contact.contact1.title && contact.contact1.number && (
          <span>{`${contact.contact1.title}: ${contact.contact1.number}`}</span>
        )}
        {contact.contact2.title && contact.contact2.number && (
          <span>{`${contact.contact2.title}: ${contact.contact2.number}`}</span>
        )}
        {contact.contact3.title && contact.contact3.number && (
          <span>{`${contact.contact3.title}: ${contact.contact3.number}`}</span>
        )}
        {contact.contact4.title && contact.contact4.number && (
          <span>{`${contact.contact4.title}: ${contact.contact4.number}`}</span>
        )}
      </div>
    </WelcomeContactContainer>
  );
}
