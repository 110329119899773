import React from "react";
import { HalfMalf } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

//contexts
import { LoadingStyles } from "./styles";

export default function Loading() {
  return (
    <LoadingStyles>
      <HalfMalf text={"Carregando..."} />
    </LoadingStyles>
  );
}
