import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from './AuthContext';
import api from '../../service/api';

export default function AuthProvider({ children }) {
  const history = useHistory();
  const [userAuth, setUserAuth] = useState(false);
  const [userInformation, setUserInformation] = useState({});

  const logout = () => {
    api.post("/admin/logout");
    localStorage.removeItem('loggedIn');
    history.push('/admin');
  }

  return (
    <AuthContext.Provider
      value={{
        userAuth,
        setUserAuth,
        userInformation,
        setUserInformation,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
