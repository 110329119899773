import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//styles
import PanelContainer from './styles';
import GlobalStyle from '../../static/css/admin_global';
import { ThemeProvider } from 'styled-components';
import AdminGlobalContext from '../../../contexts/AdminGlobalContext/GlobalContext';
import Tema from '../../static/css/theme';

//components
import Aside from '../../../components/Aside';
import Nav from '../../../components/Nav';
import Main from '../../../components/AdminContent';

//contexts
import AlertContextProvider from '../../../contexts/Alert/AlertContextProvider';

export default function Panel({ Content }) {

    const [ Theme ] = useState(Tema);

    const { theme } = useContext(AdminGlobalContext);



    return (
        <ThemeProvider theme={ theme === false ? Theme.light : Theme.dark }>
            <GlobalStyle />
            <AlertContextProvider>
                <PanelContainer>
                    <Nav />
                    <Main>
                        <Aside />
                        <Content />
                    </Main>
                </PanelContainer>
            </AlertContextProvider>
        </ThemeProvider>
    );
}
