import React, { useState, useEffect } from 'react';

//components
import Table from '../../../components/Table';

//services
import Api from '../../../service/api';

//styles
import UsersContainer from './styles';

export default function Logs() {

    const [ logRows, setLogRows ] = useState([]);
    const [query, setQuery] = useState('');
    const [ update, forceUpdate ] = useState();
    const [ loading, setLoading ] = useState(false);
    
    const header = [ 'Data', 'Usuário', 'Ocorrência', 'Resultado', 'Status' ]
    const width = Math.ceil(100/header.length)
    
    useEffect(() => {
        setQuery('');
        setLoading(true);
        Api.get('/admin/logs').then(res => {
            setLogRows(res.data.entries);
            setLoading(false);
        });
    }, [ update ])

    const Thead = () => {
        return(
            <div className='thead' style={{opacity: loading === true ? '.3' : 1}}>
                <div className='tr'>
                    <div className='th' style={{width: `${width}%`}} key={0}>Índice</div>
                    {
                        header.map((header, index) => (
                            <div className='th' style={{width: `${width}%`}} key={index + 1}>{header}</div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const Tbody = ({ handle_click }) => {
        return(
            <div className='tbody' style={{opacity: loading === true ? '.3' : 1}}>
                {
                    logRows.filter(row => row.user.toLowerCase().includes(query) || row.status.toString().includes(query)).map((row, index) => (
                        <div className='tr'  key={index}>
                            <div className='td' style={{width: `${width}%`}}>{index + 1}</div>
                            <div className='td' style={{width: `${width}%`}}>{row.timestamp.value}</div>
                            <div className='td' style={{width: `${width}%`}}>{row.user}</div>
                            <div className='td' style={{width: `${width}%`}}>{row.action}</div>
                            <div className='td' style={{width: `${width}%`}}>{row.result}</div>
                            <div className='td' style={{width: `${width}%`}}>{row.status}</div>
                        </div>
                    ))
                }
            </div>
        );
    }

    return (
        <UsersContainer>
            <Table 
                Tbody={Tbody}
                Thead={Thead}
                setQuery={setQuery}
                searchInputPlaceholder='Pesquise por usuário ou status'
                forceUpdate={forceUpdate}
                is_clickable
            />
        </UsersContainer>
    );
}
