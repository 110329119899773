import React, { useState, useEffect } from 'react';
import ContactContext from './ContactContext';


// services
import Api from '../../service/api';

export default function ContactProvider({ children }) {
  const [messageContact, setMessageContact] = useState('');
  const [contact, setContact] = useState({
    contact1: { title: '', number: '' },
    contact2: { title: '', number: '' },
    contact3: { title: '', number: '' },
    contact4: { title: '', number: '' },
  });

  const getContacts = async () => {
    try {
      await Api.get('/admin/contacts').then((res) => {
        setMessageContact(res.data.contactsArray.messageContact);
        setContact({
          contact1: {
            title: res.data.contactsArray.contact1.title,
            number: res.data.contactsArray.contact1.phoneNumber,
          },
          contact2: {
            title: res.data.contactsArray.contact2.title,
            number: res.data.contactsArray.contact2.phoneNumber,
          },
          contact3: {
            title: res.data.contactsArray.contact3.title,
            number: res.data.contactsArray.contact3.phoneNumber,
          },
          contact4: {
            title: res.data.contactsArray.contact4.title,
            number: res.data.contactsArray.contact4.phoneNumber,
          },
        });
      });
    } catch (error) {
      console.log('Error ', error);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <ContactContext.Provider
      value={{
        messageContact,
        setMessageContact,
        contact,
        setContact,
        getContacts,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
}
