import styled from 'styled-components';

export default styled.div`
  width: 100%;

  //flex
  display: flex;
  justify-content: center;
  flex-direction: column;

  .contact {
    display: flex;
    flex-flow: column nowrap;
  }

  .contact > * {
    margin-bottom: 10px;
  }

  .contact span:first-child {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
