import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Api from '../../../service/api';

//resources
import Button from '../../../components/Button';

//styles
import { QuestionsContainer, ButtonContainer } from './styles';
import { MessageError } from '../Login/styles';

//contexts
import TokenContext from '../../../contexts/Token/TokenContext';
import UserContext from '../../../contexts/UserGlobalContext/UserContext';
import AlertContext from '../../../contexts/Alert/AlertContext';
import AuthContext from '../../../contexts/Auth/AuthContext';

export default function Questions({ computedMatch, location }) {

    const { action } = computedMatch.params;
    const history = useHistory();

    const [ questions, setQuestions ] = useState([]);
    const [ answers, setAnswers ] = useState([]);

    const { setToken } = useContext(TokenContext);
    const { setAlert } = useContext(AlertContext);
    const { loading, setLoading } = useContext(UserContext);
    const { userInformation } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        Api.post('/user/questions', { id: userInformation.id, numDoc: userInformation.numDoc }).then(res => {
            setQuestions(res.data.questions);
            setLoading(false);
        });
    }, [setLoading, userInformation.id, userInformation.numDoc])

    useEffect(() => {
        if(answers.length > 0){
            let array_user_answers = questions.filter(question => question.trim() !== "").map((question, index) => {
                let newObj = {};
                newObj[question] = answers[index].trim();
                return newObj;
            })
    
            const structured_user_answers = Object.assign({}, ...array_user_answers);
            let payload_to_send = {
                id: userInformation.id,
                numDoc: userInformation.numDoc,
                questionsAnswers: structured_user_answers
            }

            Api.post('/user/verify/questions', payload_to_send).then(res => {
                const is_authorized = res.status;
                setToken(res.data.token);
                if(action === 'signup' && is_authorized === 200){
                    history.push(`/term`);
                }else if(action === 'forgot' && is_authorized === 200){
                    history.push(`/info`, location.state);
                }
            }).catch(err => {
                setAlert({
                    type: 'not ok',
                    severity: 'error',
                    message: 'Uma ou mais respostas estão incorretas, por favor, verifique-as e tente novamente. Caso o problema persista, contate um administrador.',
                    show: true
                })
                setLoading(false);
            });
        }
    }, [action, answers, history, questions, setAlert, setLoading, setToken, userInformation.id, userInformation.numDoc]) 

    const handle_submit = (answers) => {
        setLoading(true)
        const answer_list = []
        for(let [, value] of Object.entries(answers)){
            answer_list.push(value);
        }
        setAnswers(answer_list)
    }


    const [userSurname, setSurname] = useState('')
    const [isSurnameValid, setIsSurnameValid] = useState(true);
    function validateSurnameInput(surnameInput) {
        const surnamePattern = /^[A-Za-z]+$/

        setIsSurnameValid(surnamePattern.test(surnameInput));
        setSurname(surnameInput);
    }


    const [birthdayYear, setBirthdayYear] = useState('');
    const [isBirthdayYearValid, setIsBirthdayYearValid] = useState(true);
    function validateBirthdayYearInput(yearInput) {
        const yearPattern = /^\d{0,4}$/;
        
        setIsBirthdayYearValid(yearPattern.test(yearInput));
        setBirthdayYear(yearInput);
    }

    const [hiringYear, setHiringYear] = useState('');
    const [isHiringYearValid, setIsHiringYearValid] = useState(true);
    function validateHiringYearInput(yearInput) {
        const yearPattern = /^\d{0,4}$/;
        
        setIsHiringYearValid(yearPattern.test(yearInput));
        setHiringYear(yearInput);
    }

    const handleKeyPress = (event) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    
        if (!allowedKeys.includes(event.key)) {
          event.preventDefault();
        }
      };

    const handlePaste = (event) => {
        const clipboardText = event.clipboardData.getData('text');
        console.log("Test")
        if (!/^\d+$/.test(clipboardText)) {
                event.preventDefault();
        }   
    }

    const handleKeyPressLetter = (event) => {
        const allowedLetters = /^[A-Za-z]+$/;
      
        if (!allowedLetters.test(event.key)) {
          event.preventDefault();
        }
      };

    const handlePasteLetter = (event) => {
        const clipboardText = event.clipboardData.getData('text');
        console.log("Test")
        if (!/^[A-Za-z]+$/.test(clipboardText)) {
                event.preventDefault();
        }   
    }

    return (
        <QuestionsContainer>
            <Form onSubmit={handle_submit}>
                <h2>Perguntas de segurança</h2>
                
                    {/* // questions.length > 0 && loading === false
                    // ? questions.filter(item => item.trim() !== "").map((item, index) => (
                    //     <Fragment key={index}>
                    //         <label key={item}>{item?.toUpperCase()}</label>
                    //         <Input key={index} name={`question${index}`} type='text' />
                            
                    //     </Fragment>
                    // ))
                    // : null */}
                <label>
                    {questions[0]?.toUpperCase().slice(0, 13)}
                    <strong>{questions[0]?.toUpperCase().slice(13, 29)}</strong>
                    {questions[0]?.toUpperCase().slice(29, 30)}

                </label> 
                <Input 
                    name={`${questions[0]}`}
                    placeholder="Ex. Santos" 
                    onKeyPress={handleKeyPressLetter}
                    onPaste={handlePasteLetter}
                    onChange={(e) => validateSurnameInput(e.target.value)}
                    maxLength={40} 
                />
                {
                userSurname.length > 0 && !isSurnameValid ? 
                    <MessageError>Por favor, entre com um sobrenome válido.</MessageError> : 
                    <></>
                }

                <label>
                    {questions[1]?.toUpperCase().slice(0, 11)}
                    <strong>{questions[1]?.toUpperCase().slice(11, 28)}</strong>
                    {questions[1]?.toUpperCase().slice(28, 29)}
                </label> 
                <Input 
                    name={`${questions[1]}`}
                    placeholder="Ex. 1992" 
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={(e) => validateBirthdayYearInput(e.target.value)}
                    maxLength={4} 
                />
                {
                birthdayYear.length > 0 && !isBirthdayYearValid ? 
                    <MessageError>Por favor, entre com um ano válido (Exemplo: 1997).</MessageError> : 
                    <></>
                }

                <label>
                    {questions[2]?.toUpperCase().slice(0, 13)}
                    <strong>{questions[2]?.toUpperCase().slice(13, 38)}</strong>
                    {questions[2]?.toUpperCase().slice(38, 39)}
                </label> 
                <Input 
                    name={`${questions[2]}`}
                    placeholder="Ex. 2012" 
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={(e) => validateHiringYearInput(e.target.value)}
                    maxLength={4} 
                />
                {
                hiringYear.length > 0 && !isHiringYearValid ? 
                    <MessageError>Por favor, entre com um ano válido (Exemplo: 2012).</MessageError> : 
                    <></>
                }
                
                <ButtonContainer>
                    <Button>
                        REIVINDICAR
                    </Button>
                </ButtonContainer>
            </Form>
        </QuestionsContainer>
    );
}