import React, { useRef, useEffect, useState, useCallback } from "react";
import { useField } from "@unform/core";
import IconButton from "@material-ui/core/IconButton";

import FileInputContainer from "./styles";

export default function FileInput({ name, children, label, showPreview, fileType, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);
  const [preview, setPreview] = useState(undefined);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files",
    });
  }, [inputRef, fieldName, registerField]);

  const handle_preview = useCallback(e => {
    const file = e.target.files[0];
    if (!!file) {
      setPreview(file.name);
    }
  }, []);

  const capitalize_first_letter = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <FileInputContainer>
      <input
        accept={fileType}
        multiple
        type="file"
        name="table_input"
        ref={inputRef}
        className="file_input"
        id={name.split(".")[0]}
        onChange={handle_preview}
      />
      <IconButton color="inherit" size="small">
        <label htmlFor={name.split(".")[0]}>
          {label !== false ? <p>{capitalize_first_letter(name)}</p> : null}
          {children}
          {showPreview !== false ? <p>{preview}</p> : null}
        </label>
      </IconButton>
    </FileInputContainer>
  );
}
