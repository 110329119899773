import React from 'react';

//styles
import LogosContainer from './styles.js';

export default function Logos() {

  const cliente = !!sessionStorage.getItem('@userTheme') ? JSON.parse(sessionStorage.getItem('@userTheme')) : undefined;

  return (
    <LogosContainer>
      <div className="hide_logo">
          <div>
              <img src={`https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/alestlogo.svg`} alt='alest logo' />
          </div>
          
          <div>
              <img src={`https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/logo.png`} alt='cliente logo' />
          </div>

          <div>
              <img src={`https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/workplace.png`} alt='workplace logo' />
          </div>
        </div>
    </LogosContainer>
  );
}
