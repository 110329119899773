import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;

  form {
    padding: 10px;
    width: 100%;
  }

  form input {
    padding: 10px;
    border: 1px solid ${props => props.theme.input.border};
    border-radius: 3px;
    background: ${props => props.theme.input.background};
  }

  form input:focus {
    outline: none;
  }

  form h2 {
    margin-bottom: 2.2rem;
  }

  form label {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 12px;
  }

  //flex

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const MessageError = styled.p`
  color: #ff0033;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
`;