const Theme = {
  dark: {
    primary: "#333333",
    background: "#222222",
    complementary: "#E67108",
    border: "#444444",
    text: "#F1F1F1",
    hover: "#444444",
    assets: {
      logo: "#F1F1F1",
    },
    input: {
      background: "#C3C3C3",
    },
    button: {
      background: "#C3C3C3",
      hover: "#ADADAD",
    },
    table: {
      headerColor: "#FFFFFF",
      background: "#E671080d",
      hover: {
        background: "#E671082d",
      },
    },
  },
  light: {
    primary: "#FFFFFF",
    background: "#F1F1F1",
    complementary: "#1976D2",
    border: "#E5E5E5",
    text: "#333333",
    hover: "#E5E5E5",
    assets: {
      logo: "#333333",
    },
    input: {
      background: "#EDEDED",
    },
    button: {
      background: "#EDEDED",
      hover: "#E5E5E5",
    },
    table: {
      headerColor: "#FFFFFF",
      background: "#1976D21d",
      hover: {
        background: "#1976D22d",
      },
    },
  },
};

export default Theme;
