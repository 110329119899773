import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grow from '@material-ui/core/Grow';
import api from '../../service/api';

//styles
import MenuContainer from './styles';

//contexts
import AdminGlobalContext from '../../contexts/AdminGlobalContext/GlobalContext';

export default function CustomizedMenus() {

    const { theme, setTheme } = useContext(AdminGlobalContext);

    const [checked, setChecked] = useState(false);

    const history = useHistory();

    const adminInformation = JSON.parse(localStorage.getItem('loggedIn')) || {};

    const handleClick = () => {
        setChecked(!checked);
    };

    const handleClickAway = () => {
        setChecked(false);
    }

    const logout = async () => {
        api.get("/admin/logout");
        localStorage.removeItem('loggedIn');
        history.push('/admin');
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <MenuContainer>
                <IconButton size='small' onClick={handleClick} >
                    <Avatar alt={adminInformation.displayName} src={adminInformation.photoURL} />
                </IconButton>
                <Grow in={checked} style={{ transformOrigin: '500px 0 0' }} {...(checked ? { timeout: 200 } : {})}>
                    <ul>
                        <li className='dropdown_link'>Olá, {adminInformation.displayName}!</li>
                        <li className='dropdown_link' onClick={() => setTheme(!theme)}>
                            <span className='dropdown_icon'>
                                <NightsStayIcon color='inherit' fontSize='inherit' />
                            </span>
                            <span className='dropdown_text'>
                                Alterar tema
                            </span>
                        </li>
                        <li className='dropdown_link' onClick={logout}>
                            <span className='dropdown_icon'>
                                <ExitToAppIcon color='inherit' fontSize='inherit' />
                            </span>
                            <span className='dropdown_text'>
                                Sair
                            </span>
                        </li>
                    </ul>
                </Grow>
            </MenuContainer>
        </ClickAwayListener>
    );
}
