import { Form } from "@unform/web";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
//components
import Input from "../../../components/Input";
import Loading from "../../../components/Loading";
import AlertContext from "../../../contexts/Alert/AlertContext";
//services
import Api from "../../../service/api";
import { response_translate } from "../../../Tools/responseHandler";
//styles
import LoginFormContainer from "./styles";

export default function Login() {
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const verifyLoggedStorage = () => {
    const itemStr = localStorage.getItem('loggedIn');

    if(itemStr) {
        const loggedObj = JSON.parse(itemStr);
        const now = new Date();

        if(now.getTime() > loggedObj.expiry) {
            localStorage.removeItem('loggedIn');
        }
    }
    return;
  }

  useEffect(() => {
    verifyLoggedStorage();
    if (localStorage.getItem("loggedIn") !== null) {
      history.push("/admin/panel");
    }
  }, [history, loading]);

  const handle_submit = async ({ username, password }) => {
    try {
      setLoading(true);
      const res = await Api.post("/admin/auth", { username, password });
      if (res.status === 200) {
        const now = new Date();

        const loggedObj = {
          value: true,
          expiry: now.getTime() + 60 * 60 * 1000 // 1 hour
        }

        localStorage.setItem("loggedIn", JSON.stringify(loggedObj));
      }
    } catch (err) {
      setAlert({
        type: "error",
        severity: "error",
        message: response_translate(err.response.data.msg),
        show: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Alert />
      {loading ? <Loading /> : ""}
      <LoginFormContainer>
        <Form onSubmit={handle_submit}>
          <h2>Login</h2>
          <label>E-mail</label>
          <Input
            name="username"
            placeholder="usuario@dominio.com.br"
            type="email"
            autoComplete="username"
            required
          />
          <label>Senha</label>
          <Input
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />
          <div className="button">
            <Button>Entrar</Button>
          </div>
        </Form>
      </LoginFormContainer>
    </>
  );
}
