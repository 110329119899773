import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Api from '../../../service/api';

//resources
import Button from '../../../components/Button';

//styles
import { LoginContainer, MessageError, ButtonContainer } from './styles.js';

//contexts
import AuthContext from '../../../contexts/Auth/AuthContext';
import { ThemeContext } from 'styled-components';
import AlertContext from '../../../contexts/Alert/AlertContext';
import UserGlobalContext from '../../../contexts/UserGlobalContext/UserContext';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Login({ computedMatch }) {

    const { action } = computedMatch.params;
    const history = useHistory();

    const [fields, setFields] = useState({
        fields: {
            firstField: '',
            secondField: '',
        }
    })

    const { label, placeholder } = useContext(ThemeContext);
    const { setUserAuth, setUserInformation } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);
    const { setLoading } = useContext(UserGlobalContext);

    useEffect(() => {
        Api.get('/admin/loginFields').then((response) => {
            setFields(response.data.fields)
        })
    }, [])

    const handle_submit = ({ id, numDoc }) => {
        setLoading(true);
        const normalizedId = id.trim();
        const normalizedNumDoc = numDoc.trim();
        if (normalizedId === "" || normalizedNumDoc === "") {
            setAlert({
                show: true,
                message: 'Por favor, não deixe nenhum campo vazio.',
                type: 'not ok',
                severity: 'warning'
            })
            setLoading(false)
        } else {
            Api.post('user/verify/auth', { id: normalizedId, numDoc: normalizedNumDoc }).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    setUserAuth(true);
                    setUserInformation({ id: normalizedId, numDoc: normalizedNumDoc });
                    history.push({ pathname: `/questions/${action}`, state: { method: action } });
                }
            }).catch(err => {
                setLoading(false);
                setAlert({
                    type: 'not ok',
                    severity: 'error',
                    message: err.response.data.msg,
                    show: true
                });
            });
        }
    }

    const [year, setYear] = useState('');
    const [isYearValid, setIsYearValid] = useState(true);
    function validateYearInput(yearInput) {
        const yearPattern = /^\d{0,4}$/;
        
        setIsYearValid(yearPattern.test(yearInput));
        setYear(yearInput);
    }

    const [id, setId] = useState('');
    const [isIdValid, setIsIdValid] = useState(true);
    function validateIdInput(idInput) {
        const idPattern = /^\d{0,10}$/;
        
        setIsIdValid(idPattern.test(idInput));
        setId(idInput);
    }

    const handleKeyPress = (event) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

        if (!allowedKeys.includes(event.key)) {
          event.preventDefault(); 
        } 
    };

    const handlePaste = (event) => {
        const clipboardText = event.clipboardData.getData('text');
        console.log("Test")
        if (!/^\d+$/.test(clipboardText)) {
                event.preventDefault();
        }
        
    }

    return (
        <LoginContainer>
            <Form onSubmit={handle_submit}>
                <h2>CADASTRO NO WORKPLACE:</h2>
                <label>
                    {fields.firstField?.toUpperCase().slice(0, 11)}
                    <strong>{fields.firstField?.toUpperCase().slice(11, 21)}</strong>
                    {fields.firstField?.toUpperCase().slice(21, 31)}
                </label>
                <Input 
                    name='id'
                    placeholder="Ex. 0099999"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={(e) => validateIdInput(e.target.value)}
                    maxLength={10}
                />
                {
                    id.length > 0 && !isIdValid ? 
                        <MessageError>Por favor, entre com uma Matrícula válida.</MessageError> : 
                        <></>
                }

                <label>
                    {fields.secondField?.toUpperCase().slice(0, 11)}
                    <strong>{fields.secondField?.toUpperCase().slice(11, 15)}</strong>
                    {fields.secondField?.toUpperCase().slice(15, 28)}
                </label>
                <Input 
                    name='numDoc' 
                    placeholder="Ex. 1996"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={(e) => validateYearInput(e.target.value)}
                    maxLength={4}
                />
                {
                    year.length > 0 && !isYearValid ? 
                        <MessageError>Por favor, entre com um ano válido (Exemplo: 1997).</MessageError> : 
                        <></>
                }

                <ButtonContainer>
                    <Button>
                        REIVINDICAR
                    </Button>
                </ButtonContainer>
            </Form>
        </LoginContainer>
    );
}
