import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";

export default function Input({ name, type, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = "", registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: type === "file" ? "files[0]" : "value",
    });
  }, [fieldName, registerField, type]);

  return (
    <input
      ref={inputRef}
      id={fieldName}
      defaultValue={defaultValue}
      type={type}
      {...rest}
    />
  );
}
