import { initializeApp } from "firebase/app";

import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const {
    REACT_APP_PROJECT_ID,
    REACT_APP_APP_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_API_KEY,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_RECAPTCHA_KEY
} = process.env;

const app = initializeApp({
    "projectId": REACT_APP_PROJECT_ID,
    "appId": REACT_APP_APP_ID,
    "storageBucket": REACT_APP_STORAGE_BUCKET,
    "apiKey": REACT_APP_API_KEY,
    "authDomain": REACT_APP_MESSAGING_SENDER_ID,
    "messagingSenderId": REACT_APP_RECAPTCHA_KEY
});

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(REACT_APP_RECAPTCHA_KEY || ""),
    isTokenAutoRefreshEnabled: true,
});
