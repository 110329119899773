import React, { useState } from 'react';

const SettingsContact = ({
  messageTitle,
  messageNumber,
  contact,
  whatContact,
  setContact,
}) => {
  const [placeholder, setPlaceholder] = useState({
    contact1: {
      title: contact.contact1.title,
      number: contact.contact1.number,
    },
    contact2: {
      title: contact.contact2.title,
      number: contact.contact2.number,
    },
    contact3: {
      title: contact.contact3.title,
      number: contact.contact3.number,
    },
    contact4: {
      title: contact.contact4.title,
      number: contact.contact4.number,
    },
  });

  const placeholderTitle = () => {
    switch (whatContact) {
      case 'contact1':
        return placeholder.contact1.title;
      case 'contact2':
        return placeholder.contact2.title;
      case 'contact3':
        return placeholder.contact3.title;
      default:
        return placeholder.contact4.title;
    }
  };

  const placeholderNumber = () => {
    switch (whatContact) {
      case 'contact1':
        return placeholder.contact1.number;
      case 'contact2':
        return placeholder.contact2.number;
      case 'contact3':
        return placeholder.contact3.number;
      default:
        return placeholder.contact4.number;
    }
  };

  const onChangeTitleContact = (value) => {
    switch (whatContact) {
      case 'contact1':
        setContact({
          ...contact,
          contact1: {
            ...contact.contact1,
            title: value,
          },
        });
        break;
      case 'contact2':
        setContact({
          ...contact,
          contact2: {
            ...contact.contact2,
            title: value,
          },
        });
        break;
      case 'contact3':
        setContact({
          ...contact,
          contact3: {
            ...contact.contact3,
            title: value,
          },
        });
        break;
      default:
        setContact({
          ...contact,
          contact4: {
            ...contact.contact4,
            title: value,
          },
        });
        break;
    }
  };

  const onChangeNumberContact = (value) => {
    switch (whatContact) {
      case 'contact1':
        setContact({
          ...contact,
          contact1: {
            ...contact.contact1,
            number: value,
          },
        });
        break;
      case 'contact2':
        setContact({
          ...contact,
          contact2: {
            ...contact.contact2,
            number: value,
          },
        });
        break;
      case 'contact3':
        setContact({
          ...contact,
          contact3: {
            ...contact.contact3,
            number: value,
          },
        });
        break;
      default:
        setContact({
          ...contact,
          contact4: {
            ...contact.contact4,
            number: value,
          },
        });
        break;
    }
  };

  const deleteValues = () => {
    switch (whatContact) {
      case 'contact1':
        setPlaceholder({
          ...placeholder,
          contact1: {
            number: '',
            title: '',
          },
        });
        setContact({
          ...contact,
          contact1: {
            title: '',
            number: '',
          },
        });
        break;
      case 'contact2':
        setPlaceholder({
          ...placeholder,
          contact2: {
            number: '',
            title: '',
          },
        });
        setContact({
          ...contact,
          contact2: {
            title: '',
            number: '',
          },
        });
        break;
      case 'contact3':
        setPlaceholder({
          ...placeholder,
          contact3: {
            number: '',
            title: '',
          },
        });
        setContact({
          ...contact,
          contact3: {
            title: '',
            number: '',
          },
        });
        break;
      default:
        setPlaceholder({
          ...placeholder,
          contact4: {
            number: '',
            title: '',
          },
        });
        setContact({
          ...contact,
          contact4: {
            title: '',
            number: '',
          },
        });
        break;
    }
  };

  return (
    <div className="numbers_box">
      <span>
        <p>{messageTitle}</p>
        <input
          type="text"
          placeholder={placeholderTitle()}
          onChange={({ target }) => onChangeTitleContact(target.value)}
        />
      </span>
      <span>
        <p className="numbers">{messageNumber}</p>
        <input
          type="tel"
          placeholder={placeholderNumber()}
          onChange={({ target }) => onChangeNumberContact(target.value)}
        />
      </span>
      <button style={{ width: '30%' }} onClick={deleteValues}>Limpar dados</button>
    </div>
  );
};

export default SettingsContact;
