import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';

//contexts
import AuthProvider from './contexts/Auth/AuthProvider';
import TokenProvider from './contexts/Token/TokenProvider';
import ContactProvider from './contexts/Contact/ContactProvider';

ReactDOM.render(
  <AuthProvider>
    <TokenProvider>
      <ContactProvider>
        <Routes />
      </ContactProvider>
    </TokenProvider>
  </AuthProvider>,
  document.getElementById('root')
);
