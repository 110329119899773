import React, { useRef } from "react";
import { Form } from "@unform/web";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ReactTooltip from "react-tooltip";

import Search from "@material-ui/icons/Search";

import { SearchStyles } from "./styles";
import Input from "../Input";

export default function SeachInput({
  setQuery,
  searchInputPlaceholder,
  forceUpdate,
  nextPage,
  prevPage,
  page,
}) {
  const form = useRef(null);

  const handle_filter = ({ query }, { reset }) => {
    setQuery(query);
    reset();
  };

  const clearFilter = () => {
    form.current.reset();
    forceUpdate(Math.random());
  };

  return (
    <SearchStyles>
      <Form ref={form} onSubmit={handle_filter}>
        <div
          style={{
            width: "82vw",
            display: "flex",
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="searchInputContainer">
              <Input
                className="inputSearch"
                type="text"
                name="query"
                placeholder={searchInputPlaceholder}
              />
              <IconButton
                size="medium"
                color="inherit"
                id="search"
                className="searchIcon"
                type="submit"
              >
                <Search />
              </IconButton>
            </div>
            <IconButton
              size="small"
              color="inherit"
              id="clearFilter"
              onClick={clearFilter}
            >
              <ClearAllIcon fontSize="large" />
            </IconButton>
            <label style={{ width: 40 }} htmlFor="clearFilter">
              Limpar Filtro
            </label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              width: 70,
              justifyContent: "space-between",
            }}
          >
            {page === 0 ? (
              <div
                data-tip
                data-for="prevButton"
                style={{
                  backgroundColor: "#F3F3F3",
                  opacity: "80%",
                  borderRadius: 20,
                  width: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton disabled size="small" onClick={prevPage}>
                  <KeyboardArrowLeft
                    fontSize="large"
                    style={{ color: "black" }}
                  />
                </IconButton>
                <ReactTooltip id="prevButton" place="top" effect="solid">
                  Página anterior
                </ReactTooltip>
              </div>
            ) : (
              <div
                data-tip
                data-for="prevButton"
                style={{
                  backgroundColor: "#F3F3F3",
                  borderRadius: 20,
                  width: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton size="small" onClick={prevPage}>
                  <KeyboardArrowLeft
                    fontSize="large"
                    style={{ color: "black" }}
                  />
                </IconButton>
                <ReactTooltip id="prevButton" place="top" effect="solid">
                  Página anterior
                </ReactTooltip>
              </div>
            )}
            <div
              data-tip
              data-for="nextButton"
              style={{
                backgroundColor: "#F3F3F3",
                borderRadius: 20,
                width: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton size="small" onClick={nextPage}>
                <KeyboardArrowRight
                  fontSize="large"
                  style={{ color: "black" }}
                />
              </IconButton>
              <ReactTooltip id="nextButton" place="top" effect="solid">
                Próxima página
              </ReactTooltip>
            </div>
          </div>
        </div>
      </Form>
    </SearchStyles>
  );
}
