import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: 100%;

  //flex
  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    align-self: center;
    text-decoration: none;
    color: #0000EE;
    margin-top: 1.2rem;
  }
`;
