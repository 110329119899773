import React from "react";
import styled from "styled-components";

export const LoadingStyles = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.5);


  color: #000;
  font-size: 26px;
  .spinner-container {
    all: unset;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    opacity: 1;
  }
`;
